import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { getStorage ,ref,uploadBytes} from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyCv5zREMBDOoacIwZ6Xn_DYa0TFYE3UTpA",
  authDomain: "wissre-d6d91.firebaseapp.com",
  projectId: "wissre-d6d91",
  storageBucket: "wissre-d6d91.appspot.com",
  messagingSenderId: "890368419715",
  appId: "1:890368419715:web:a7678b45da22ff314338b6"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); // auth 

export const storage = getStorage(app);// storage 

export function upfileFirebase(file) {
 const strageRef = ref(storage, file.name);
  uploadBytes(strageRef, file).then((snapshot) => {

  })
}
const provider = new GoogleAuthProvider()
export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      
      })
    .catch((err) => {});
};
